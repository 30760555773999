import {createStore} from 'vuex';

import $router from '@/router'
import $axios from '@/axios'

import {items} from '@/libs/menuItems'


export default createStore({
    state(){
        return {
            collapse:false,      // 折叠状态
            loginState:false,    // 登录状态
            username:undefined, // 用户名
            companyNumber:undefined,// 是否是官方
            token:undefined,    // token
            ws:'wss://hagonne.cc',  // 推送设备实时数据的域名端口地址
            // ws:'ws://localhost:8999',  // 本地测试

            // ws2:'ws://localhost:8998',  // 本地推送设备实时预警信息的域名端口地址
        }
    },
    mutations:{
        // 修改折叠状态
        handleCollapse(state,data){
            state.collapse = data;
        },
        // 修改登录状态
        setLoginState(state,data){
            state.loginState = data;
        },
        // 修改用户名
        setUsername(state,data){
            state.username = data;
        },
        // 修改公司编号
        setCompanyNumber(state,data){
            state.companyNumber = data;
        },
        // 修改token
        setToken(state,data){
            state.token = data;
        },
    },
    actions:{
        // 侧边栏折叠
        handleCollapse({commit},data){
            commit('handleCollapse',data);
        },

        // 登录成功
        async login({commit},obj){
            localStorage.setItem('loginStatus',true);
            commit('setLoginState',true);
            commit('setUsername',obj.username);

            try {
                const {data} = await $axios({url:'/getPermission',});
                if(data.code == 200){
                    commit('setToken',data.data.sessionId);
                    commit('setCompanyNumber',data.data.companyNumber);

                    const arr = [
                        {
                            path:'/',
                            redirect:'/dashboard',
                        },
                        {
                            path:'/',
                            name:'home',
                            component:()=>import('@/components/common/Home'),
                            children:[
                                {
                                    path:'/dashboard',
                                    name:'dashboard',
                                    meta:{
                                        title:'系统首页',
                                    },
                                    component:()=>import('@/views/Dashboard')
                                }
                            ],
                        },
                    ];

                    if(data.data.permissions.length){
                        const tmpURL = data.data.permissions.map(item => item.url);

                        for (let i = 0;i < tmpURL.length;i++){
                            for (let j = 0;j < items[0].children.length;j++){
                                if(tmpURL[i].indexOf(items[0].children[j].path) != -1){
                                    const array = arr[1].children.filter(item => item.path == items[0].children[j].path);
                                    if(!array.length){
                                        arr[1].children.push(items[0].children[j]);
                                    }
                                }
                            }
                        }
                    }

                    for (let i = 0;i<arr.length;i++){
                        $router.addRoute(arr[i]);
                    }

                    $router.push('/');
                }
            }catch (e) {
                console.log(e);
            }
        },

        // 获取用户信息
        async getUserInfo({commit}){
            const {loginStatus} = localStorage;
            if(loginStatus){
                const {data} = await $axios({url:'/getPermission',});
                if(data.code === 200){
                    commit('setLoginState',true);
                    commit('setUsername',data.data.username);
                    commit('setCompanyNumber',data.data.companyNumber);
                    commit('setToken',data.data.sessionId);
                    const arr = [
                        {
                            path:'/',
                            redirect:'/dashboard',
                        },
                        {
                            path:'/',
                            name:'home',
                            component:()=>import('@/components/common/Home'),
                            children:[
                                {
                                    path:'/dashboard',
                                    name:'dashboard',
                                    meta:{
                                        title:'系统首页',
                                    },
                                    component:()=>import('@/views/Dashboard')
                                }
                            ],
                        },
                    ];

                    if(data.data.permissions.length){
                        const tmpURL = data.data.permissions.map(item => item.url); // 返回所拥有权限的数组

                        for (let i = 0;i < tmpURL.length;i++){
                            for (let j = 0;j < items[0].children.length;j++){   // 放在本地文件中的权限数组
                                if(tmpURL[i].indexOf(items[0].children[j].path) != -1){
                                    const array = arr[1].children.filter(item => item.path == items[0].children[j].path);
                                    if(!array.length){
                                        arr[1].children.push(items[0].children[j]);
                                    }
                                }
                            }
                        }
                    }

                    for (let i = 0;i < arr.length;i++){
                        $router.addRoute(arr[i]);
                    }

                    $router.push(location.pathname);
                }else{
                    //
                }
            }else{
                $router.push({path:'/login'});
            }
        },

        // 退出系统
        async logout({commit}){
            localStorage.removeItem('loginStatus');
            commit('setLoginState',false);
            commit('setUsername',undefined);
            commit('setCompanyNumber',undefined);
            commit('setToken',undefined);

            $router.push({path:'/login'});
        },
    },
    getters:{
        // 返回折叠状态
        getCollapse(state){
            return state.collapse;
        },
        // 返回用户名
        getUsername(state){
            return state.username;
        },
        // 返回是否是官方公司flag
        getCompanyNumber(state){
            return state.companyNumber;
        },
        // 返回token
        getToken(state){
            return state.token;
        },
        // 返回设备实时数据
        getWS(state){
            return state.ws;
        },
        // 返回设备实时预警数据，线上用不到
        // getWS2(state){
        //     return state.ws2;
        // }
    }
});
